import { DIMENSION_TITLES } from './analytics'
import { EXPLORER_TYPE } from './renderTypes'
import {
  ExplorerChartContentType,
  type ExplorerContentType,
  ExplorerTableContentType,
  BdeseTemplate
} from '~/types/bdese'

const SHARED_ATTRIBUTES = {
  QUERY: 'query',
  COMPUTED_AT: 'computed-at'
} as const

export const TABLE_ATTRIBUTES = {
  ...SHARED_ATTRIBUTES,
  YEAR_TABLE_WITH_TREND: 'year-table-with-trend',
  WITH_TOTAL: 'with-total'
} as const

export const TABLE_ROW_ATTRIBUTES = {
  IS_HEADER_ROW: 'is-header-row',
  IS_TOTAL_ROW: 'is-total-row'
} as const

export const TABLE_CELL_ATTRIBUTES = {
  INITIAL_VALUE: 'initial-value',
  EDITED: 'edited',
  DIMENSIONS_WITH_VALUE: 'dimensions-with-value'
} as const

export const IMAGE_ATTRIBUTES = {
  ...SHARED_ATTRIBUTES,
  CHART_TYPE: 'chart-type'
} as const

export const EXPLORER_RENDERER_BY_CONTENT_TYPE: {
  [key in ExplorerContentType]: EXPLORER_TYPE
} = {
  [ExplorerTableContentType.YEAR_TABLE]: EXPLORER_TYPE.KPI,
  [ExplorerTableContentType.STANDARD_TABLE]: EXPLORER_TYPE.KPI,
  [ExplorerChartContentType.BAR_CHART]: EXPLORER_TYPE.BAR,
  [ExplorerChartContentType.DOUGHNUT_CHART]: EXPLORER_TYPE.DOUGHNUT
}

export const BDESE_TEMPLATE_CONFIG: {
  [key in BdeseTemplate]: {
    title: string
    description: string
    iconPath: string
    companySize: string
  }
} = {
  [BdeseTemplate.BASIC_SMALL_COMPANY]: {
    title: 'legalReports.createBdese.template.basicSmallCompany.title',
    description:
      'legalReports.createBdese.template.basicSmallCompany.description',
    iconPath: new URL(
      '/assets/images/logo-french-admin.png',
      import.meta.url
    ).toString(),
    companySize: '👤 50-299'
  },
  [BdeseTemplate.BASIC_LARGE_COMPANY]: {
    title: 'legalReports.createBdese.template.basicLargeCompany.title',
    description:
      'legalReports.createBdese.template.basicLargeCompany.description',
    iconPath: new URL(
      '/assets/images/logo-french-admin.png',
      import.meta.url
    ).toString(),
    companySize: '👤 300+'
  }
}

export const REALIZED_YEAR_OPTIONS = [1, 2, 3]
export const TREND_YEAR_OPTIONS = [0, 1, 2, 3]

export const BDESE_SCOPE_MAIN_DIMENSION_TITLE =
  DIMENSION_TITLES.EMPLOYEE_SUBORGANIZATION

export const BDESE_SCOPE_FILTER_MAX_COUNT = 2
